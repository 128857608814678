<template>
  <div>
    <vs-button @click="handleOpenAssignEMRClinicSidebar" color="primary" type="filled">Assign Clinic</vs-button>
    <vs-sidebar
      class="sidebarx EMRClinicSidebarClass relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="sidebarActive"
    >
      <div class="headerClass space-y-3">
        <p class="text-xl font-semibold px-4 pt-4">Add EMR Clinics</p>
        <p class="px-4">
          Doctor can be assigned to multiple EMR clinics. Select clinics to assign doctor
        </p>
        <vs-divider></vs-divider>
        <vs-input
          v-model="searchClinic"
          @input="onSearchClinic"
          placeholder="Search clinics"
          class="w-full pb-4 px-4"
        />
      </div>
      <!-- BODY -->
      <div class="text-center my-20" v-if="isLoading">Loading...</div>
      <div v-else-if="filteredEmrClinicsList.length > 0" class="scrollableContent">
        <div v-for="item in filteredEmrClinicsList" :key="item._id">
          <vs-checkbox
            class="custom-checkbox"
            v-model="selectedClinics"
            :disabled="item.isAssigned"
            :vs-value="item._id"
          >
            <span
              :class="{ 'opacity-50 select-none': item.isAssigned }"
              class="text-ellipsis overflow-hidden"
            >
              {{ item.clinicName }}
            </span>
          </vs-checkbox>
        </div>
      </div>
      <div class="text-center my-20" v-else>No clinics found</div>
      <!-- FOOTER -->
      <div class="footerClass flex flex-col gap-3" slot="footer">
        <vs-button @click="assignDoctor" :disabled="isSavingClinics">
          <vs-icon
            v-if="isSavingClinics"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleExitProductSidebar">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: 'assign-emr-clinic',
  props: {
    assinedClinics: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false,
      sidebarActive: false,
      selectedClinics: [],
      emrClinicsList: [],
      filteredEmrClinicsList: [],
      emrClinic: null,
      searchClinic: "",
      isSavingClinics: false,
    }
  },
  methods: {
    handleOpenAssignEMRClinicSidebar() {
      this.sidebarActive = true;
    },
    handleExitProductSidebar() {
      this.sidebarActive = false;
      if (this.searchClinic) {
        this.searchClinic = "";
        // emit an event to get the new EMRclinics list
      }
    },
    onSearchClinic: _.debounce(function (searchText) {
      const search = searchText.trim();
      if (!search) {
        this.filteredEmrClinicsList = this.emrClinicsList;
        return;
      }

      const regex = new RegExp(search, "i"); // Case-insensitive search
      this.filteredEmrClinicsList = this.emrClinicsList.filter(clinic => regex.test(clinic.clinicName));
    }, 500),
    assignDoctor() {
      try {
        if(!this.selectedClinics.length > 0){
          throw new Error("Please select clinics")
        }
        this.isSavingClinics = true;
        this.$vs.loading()
        this.$store.dispatch('doctor/assignEmrClinics', {
          clinicIds: this.selectedClinics,
          userId: this.$route.params.doctorId
        })
        this.isSavingClinics = false;
        setTimeout( ()=> {
          this.resetFields()
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Success',
          text: 'Assigned doctors successfully.',
          color: 'success'
        });
      } catch (error) {
        this.isSavingClinics = false;
        setTimeout( ()=> {
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Error',
          text: 'Failed to assign EMR Clinic. Try again',
          color: 'danger'
        });
      }
    },
    async getClinics() {
      this.isLoading = true
      const response = await this.$store.dispatch('doctor/fetchAssignEmrClinics', this.$route.params.doctorId)
      const data = response.data.data.map(item => {
        if(this.assinedClinics.map(clinic => `${clinic._id}`).includes(item._id)){
          item.isAssigned = true
        }
        else{
          item.isAssigned = false
        }
        return item
      })

      this.emrClinicsList = data
      this.filteredEmrClinicsList = data
      this.isLoading = false
    },
    resetFields() {
      this.$emit("getEMRClinicsList");
      this.selectedClinics = []
      this.emrClinicsList = []
      this.sidebarActive = false
    }
  },
  watch: {
    sidebarActive(val) {
      if(val){
        this.getClinics()
      }
    }
  },
}
</script>

<style>
.assignDoctor .vs-button{
  border: 1px solid rgba(var(--vs-primary),1);
  padding: .679rem 2rem !important;
}
.headerClass {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.EMRClinicSidebarClass .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}
.EMRClinicSidebarClass .vs-sidebar--items {
  display: flex;
  flex-direction: column;
  padding: 0;
}
@media (min-width: 768px) {
  .EMRClinicSidebarClass .vs-sidebar-primary {
    max-width: 350px;
  }
}
.scrollableContent {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: 100%;
}
.footerClass {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}
</style>